import { navigate } from "vike/client/router";
import { localeDefault } from './locales'
import extractLocale from './extractLocale'

const navigateLocalized = (href, locale, opts = {}) => {
  let { urlPathnameWithoutLocale } = extractLocale(href)
  if (locale !== localeDefault) {
    urlPathnameWithoutLocale = '/' + locale + urlPathnameWithoutLocale
  }
  navigate(urlPathnameWithoutLocale, opts)
}

export default navigateLocalized
